import i18n from '@/plugins/i18n';

export const SHIPMENT_METHOD_CODES_SHORT = ['inpost', 'pocztaPolska', 'ups', 'fedex',
    'orlenPaczka', 'dhl', 'dbSchenker', 'dpd', 'geis', 'gls', 'raben', 'suus'];

export const SHIPMENT_METHOD_CODES = [
    {
        id: 'inpost',
        name: i18n.t('speditors.inpost'),
    },
    {
        id: 'pocztaPolska',
        name: i18n.t('speditors.pocztapolska'),
    },
    {
        id: 'ups',
        name: i18n.t('speditors.ups'),
    },
    {
        id: 'fedex',
        name: i18n.t('speditors.fedex'),
    },
    {
        id: 'orlenPaczka',
        name: i18n.t('speditors.orlenpaczka'),
    },
    {
        id: 'dhl',
        name: i18n.t('speditors.dhl'),
    },
    {
        id: 'dbSchenker',
        name: i18n.t('speditors.dbschenker'),
    },
    {
        id: 'dpd',
        name: i18n.t('speditors.dpd'),
    },
    {
        id: 'geis',
        name: i18n.t('speditors.geis'),
    },
    {
        id: 'gls',
        name: i18n.t('speditors.gls'),
    },
    {
        id: 'raben',
        name: i18n.t('speditors.raben'),
    },
    {
        id: 'suus',
        name: i18n.t('speditors.suus'),
    },
];
