<template>
  <vee-form
    v-if="confData"
    ref="form"
    v-slot="{handleSubmit , invalid }"
  >
    <v-form @submit.prevent="handleSubmit(saveSet)">
      <v-container>
        <v-row>
          <v-col
            offset="6"
            cols="3"
            class="btnRow"
          >
            <v-btn
              class="saveOrg"
              elevation="0"
              :disabled="invalid"
              @click="saveSet"
            >
              <v-icon
                small
                class="pr-2"
              >
                {{ $t("courierSet.saveIcon") }}
              </v-icon>
              {{ $t("courierSet.save") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row justify="space-around">
          <v-col
            offset="2"
            cols="2"
          >
            <v-card elevation="0">
              <div>
                <v-img
                  :src="logo"
                  contain
                  height="128px"
                  width="241px"
                  class="center"
                />
              </div>
              <v-col>
                <v-divider />
              </v-col>
              <v-responsive />
              <v-list
                dense
                nav
              >
                <div
                  v-for="component in components"
                  id="navbar"
                  :key="component.code"
                  :class="component.code===currentComponent ? 'clicked' : 'navbar'"
                  tabindex="0"
                  @click="changeComponent(component.code)"
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t(component.name) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-list>
            </v-card>
          </v-col>
          <v-col
            offset="1"
            cols="5"
            class="ml-0"
          >
            <ServiceAuthorization
              v-show="currentComponent==='ServiceAuthorization'"
              v-model="serviceAuthorization"
              :conf-data="confData"
              :is-new="isNew"
              @input="update"
            />

            <ServiceParameters
              v-show="currentComponent==='ServiceParameters'"
              v-model="serviceParameters"
              :conf-data="confData"
              @input="update"
            />

            <Parcel
              v-show="currentComponent==='Parcel'"
              v-model="parcel"
              :conf-data="confData"
              @input="update"
            />

            <AdditionalPayments
              v-show="currentComponent==='AdditionalPayments'"
              v-model="additionalPayments"
              :conf-data="confData"
              @input="update"
            />

            <AdrService
              v-show="currentComponent==='AdrService'"
              v-model="adrService"
              :conf-data="confData"
              @input="update"
            />
          </v-col>
          <v-responsive />
        </v-row>
      </v-container>
    </v-form>
  </vee-form>
</template>

<script>
import { roles } from '@/constants/Roles';
import { speditors } from '@/constants/Speditors';
import configurationApiService from '@/services/spedition/configuration-api';
import { mapGetters } from 'vuex';
import ServiceAuthorization from '@/views/Speditors/Suus/Forms/ServiceAuthorization.vue';
import ServiceParameters from '@/views/Speditors/Suus/Forms/ServiceParameters.vue';
import Parcel from '@/views/Speditors/Suus/Forms/Parcel.vue';
import AdditionalPayments from '@/views/Speditors/Suus/Forms/AdditionalPayments.vue';
import AdrService from '@/views/Speditors/Suus/Forms/AdrService.vue';
import Suus from '@/interfaces/Speditors/Suus/Suus';

export default {
  components: {
    ServiceAuthorization,
    ServiceParameters,
    Parcel,
    AdditionalPayments,
    AdrService,
  },
  props: { confSetId: { type: String, default: '' }, usrId: { type: String, default: '' } },
  data: () => (
    {
      confData: null,
      isNew: null,
      parcel: null,
      serviceAuthorization: null,
      serviceParameters: null,
      sender: null,
      loading: null,
      adrService: null,
      unloading: null,
      receiver: null,
      additionalPayments: null,
      roles,
      speditors,
      service: 'none',
      currentComponent: 'ServiceAuthorization',
      components: [{ code: 'ServiceAuthorization', name: 'courierSet.serviceAuthorizationName' },
        { code: 'ServiceParameters', name: 'courierSet.serviceParametersName' },
        { code: 'Parcel', name: 'courierSet.parcelName' },
        { code: 'AdditionalPayments', name: 'courierSet.additionalPaymentsName' },
        { code: 'AdrService', name: 'courierSet.AdrService' }],
    }),
  computed: {
    ...mapGetters(['oidcStore/oidcUser']),
    userRole() {
      if (this['oidcStore/oidcUser']) {
        const user = this['oidcStore/oidcUser'];
        return user.roles?.find((x) => roles.ALL.some((y) => y === x));
      }
      return null;
    },
    logo() {
      const spedLogo = this.speditors
        .find((x) => x.code === this.speditor).logo;
      const images = require.context('@/assets/images/logo/speditors/', false, /\.png$/);
      return images(`./${spedLogo}`);
    },
  },
  async created() {
    this.organizationId = this.$route?.params?.orgId;
    this.speditor = 'Suus';
    await this.getConfiguration();
  },

  methods: {
    update() {
      if (this.serviceAuthorization) {
        this.confData.configurationName = this.serviceAuthorization.parameters.configurationName;
        this.confData.id = this.serviceAuthorization.parameters.id;
        this.confData.clientId = this.serviceAuthorization.parameters.clientId;
        this.confData.login = this.serviceAuthorization.parameters.login;
        this.confData.password = this.serviceAuthorization.parameters.password;
        this.confData.urlAddress = this.serviceAuthorization.parameters.urlAddress;
      }
      if (this.serviceParameters) {
        this.confData.suusDefaultValues.header = this.serviceParameters.parameters;
      }
      if (this.parcel) {
        this.confData.suusDefaultValues.package = this.parcel.parameters;
      }
      if (this.additionalPayments) {
        this.confData.suusDefaultValues.cod = this.additionalPayments.parameters;
        this.confData.suusDefaultValues.header.freight = this.additionalPayments.freight;
        this.confData.suusDefaultValues.header.currency = this.additionalPayments.currency;
      }
      if (this.adrService) {
        this.confData.suusDefaultValues.adrService = this.adrService.parameters;
      }
    },
    async saveSet() {
      this.confData.tenantId = this.organizationId;
      this.confData.suusDefaultValues.tenantId = this.organizationId;
      this.confData.suusDefaultValues.header.tenantId = this.organizationId;
      this.confData.suusDefaultValues.package.tenantId = this.organizationId;
      this.confData.suusDefaultValues.cod.tenantId = this.organizationId;
      this.confData.suusDefaultValues.adrService.tenantId = this.organizationId;

      this.$store.dispatch('setConfigSet', this.confData);

      if (this.isNew) {
        await configurationApiService.createConfigurationSet(
          'Suus',
          this.confData,
          this.organizationId,
        );
      } else {
        await configurationApiService.updateConfigurationSet(
          'Suus',
          this.confData,
          this.organizationId,
        );
      }
      this.$store.dispatch('clearValues');
      this.$router.go(-1);
    },
    changeComponent(component) {
      this.currentComponent = component;
    },
    async getConfiguration() {
      if (this.confSetId) {
        this.isNew = false;
        const confSet = await configurationApiService.getConfigurationSet(
          'Suus',
          this.confSetId,
          this.usrId,
          this.organizationId,
        );
        this.confData = confSet.data;
        await this.$store.dispatch('setConfigSet', { response: this.confData });
      } else {
        this.confData = new Suus();
        this.isNew = true;
        this.confData.courier = 'Suus';
        await this.$store.dispatch('setConfigSet', this.confData);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/utilities/variables";
.clicked{
border-left: 3px solid $primary-lighten1;
  background-color: $primary-lighten4;
}
.navbar:hover{
  border-left: 3px solid $primary-lighten4;
  background-color: $primary-lighten4;
}
.navbar{
  border-left: 3px solid $white;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;

}
.btnRow {
  display: flex;
  align-self: center;
  justify-content: flex-end;
  padding-bottom: 5px;
  padding-right: 25px;
}
.v-btn.saveOrg {
  background-color: $primary-lighten1 !important;
  margin-right: 0px;
  color: white;
}
</style>
