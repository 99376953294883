<template>
  <v-container class="pt-0">
    <v-card
      elevation="0"
    >
      <v-card-title class="pb-0">
        {{ $t("courierSet.freight").toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col>
            <text-field
              v-model="freight"
              :title="$t('courierSet.freightInternational')"
              rules="double:2"
              @input="update"
            />
          </v-col>
          <v-col>
            <select-field
              v-model="currency"
              :title="$t('courierSet.currency')"
              :filed-items="currencies"
              @input="update"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import TextField from '@/components/inputs/TextField.vue';
import SelectField from '@/components/inputs/SelectField.vue';

export default {
  components: {
    TextField, SelectField,
  },
  props: {
    confData: { type: Object, default: null },
    value: { type: Object, default: null },
    isNew: { type: Boolean, default: null },
  },

  data: () => ({
    confSet: null,
    id: null,
    tenantId: null,
    cod: null,
    header: null,
    amount: null,
    freight: null,
    currency: null,
    currencies: [
      {
        id: 0,
        name: 'PLN',
      },
      {
        id: 1,
        name: 'EUR',
      },
      {
        id: 2,
        name: 'USD',
      },
      {
        id: 3,
        name: 'CHF',
      },
      {
        id: 4,
        name: 'SEK',
      },
      {
        id: 5,
        name: 'NOK',
      },
      {
        id: 6,
        name: 'CZK',
      },
      {
        id: 7,
        name: 'RON',
      },
      {
        id: 8,
        name: 'HUF',
      },
      {
        id: 9,
        name: 'HRK',
      },
      {
        id: 10,
        name: 'BGN',
      },
      {
        id: 11,
        name: 'DKK',
      },
      {
        id: 12,
        name: 'GBP',
      },
      {
        id: 13,
        name: 'RSD',
      },
      {
        id: 14,
        name: 'RUB',
      },
      {
        id: 15,
        name: 'TRY',
      },
      {
        id: 16,
        name: 'INNA',
      },
    ],

  }),
  created() {
    this.getConfSet();
  },
  methods: {
    update() {
      this.additionalPayments = {
        parameters: {
          clientEntityState: this.isNew ? 4 : 3,
          id: this.id,
          tenantId: this.tenantId,
          amount: this.amount,
        },
        freight: this.freight,
        currency: this.currency,
      };
      this.$emit('input', this.additionalPayments);
    },
    getConfSet() {
      this.confSet = this.confData;
      this.cod = this.confSet?.suusDefaultValues?.cod;
      this.id = this.cod?.id ? this.cod?.id : null;
      this.tenantId = this.cod?.tenantId ? this.cod?.tenantId : null;
      this.amount = this.cod?.amount ? this.cod?.amount : null;
      this.header = this.confSet?.suusDefaultValues?.header;
      this.freight = this.header?.freight ? this.header?.freight : null;
      this.currency = this.header?.currency ? this.header?.currency : null;
    },

  },
};
</script>

<style scoped lang="scss">
.v-select{
  padding: 0px 10px 0px 10px;
}
.col{
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
